<template>
  <div class="my-order">
    <div class="pick d-flex">
      <div
        :class="optionsIndex == index ? 'pick-item-cur' : 'pick-item'"
        v-for="(item, index) in options"
        @click="pick(index)"
      >
        <div class="pick-cur">{{ item }}</div>
      </div>
    </div>
    <div class="label d-none d-lg-flex">
      <section class="col-6">commodity</section>
      <section class="col-2">price</section>
      <section class="col-2">state</section>
      <section class="col-2">operation</section>
    </div>
    <div class="list">
      <div class="item" v-for="item in orderslist">
        <div class="item-top d-flex">
          <section class="col-5 col-lg-6">{{ item.create_time }}</section>
          <section class="col-7 col-lg-6">
            order number: <span style="color: #4c4c4c">{{ item.ordersn }}</span>
          </section>
        </div>

        <div class="item-order d-lg-flex">
          <div class="item-order-left col-lg-6 d-flex">
            <div class="item-list col-12">
              <div
                class="item-coatProduct d-flex"
                v-for="t in item.ordersgoods"
              >
                <section class="col-4">
                  <img :src="t.thumb" alt="" />
                </section>
                <section class="col-7" style="color: #4c4c4c">
                  <p style="margin-bottom: 0.25rem">
                    <p>{{ t.description }}</p>  
                    <p> {{ t.item_name }}</p>
                  </p>
                  <p
                    @click="path(t.id, 0)"
                    v-if="
                      t.status == -1 && (item.status == 5 || item.status == 6)
                    "
                    style="color: #71b247;
                      cursor: pointer;
                  border: 1px solid #71b247 ;
                  margin: 0.25rem 0.25rem 0 0.25rem;
                  border-radius: 0.1rem;
                    "
                  >
                    Single apply for after sale
                  </p>
                  <p v-if="t.status == 0" style="color: #ff7f00">
                    Apply for return|exchange
                  </p>
                  <p v-if="t.status == 1" style="color: #ff7f00">Returning</p>
                  <p v-if="t.status == 2" style="color: red">Return failed</p>
                  <p v-if="t.status == 3" style="color: #ff7f00">In exchange</p>
                  <p v-if="t.status == 4" style="color: red">Exchange failed</p>
                  <p v-if="t.status == 5" style="color: #ff7f00">Returned</p>
                  <p v-if="t.status == 6" style="color: #ff7f00">Completed</p>
                </section>
                <section class="col-1">*{{ t.num }}</section>
              </div>
            </div>
          </div>
          <div class="item-order-right col-lg-6 d-flex">
            <section class="col-4">${{ item.total }}</section>
            <section class="col-4" >
              <p v-if="item.status == 1" style="color: #71b247">
                Wait for confirm
              </p>
              <p v-if="item.status == 2" style="color: #71b247">
                Wait for delivery
              </p>
              <p v-if="item.status == 3">cancelled</p>
              <p v-if="item.status == 4">Wait for receipt</p>
              <p v-if="item.status == 5">Completed</p>
              <p v-if="item.status == 6">Aftersales processing</p>

              
             
            </section>
            <section class="col-4">
              <!-- 取消订单 -->

               <p @click="detail(item.id)" 
              style="
              cursor: pointer;
                  border: 1px solid ;
                  margin: 0.25rem 0.25rem 0 0.25rem;
                  border-radius: 0.1rem;
                ">
                order details
              </p>

              <div
                class="right-button"
                style="
                  border: 1px solid;
                  margin: 0.25rem 0.25rem 0 0.25rem;
                  border-radius: 0.1rem;
                "
                @click="cancelOrders(item.id)"
                v-if="item.status == 1"
              >
                cancelled
              </div>
              <!-- 申请售后 -->
              <div
                class="right-button"
                style="
                  color:#71b247;
                  border: 1px solid #71b247;
                  margin: 0.25rem 0.25rem 0 0.25rem;
                  border-radius: 0.1rem;
                "

              

                v-if="item.status == 5"
                @click="path(item.id, 1)"
              >
               All apply for after sale
              </div>

              <!-- 确认收货 -->
              <div
                class="right-button"
                style="
                  color: #71b247;
                  border: 1px solid #71b247;
                  margin: 0.25rem 0.25rem 0 0.25rem;
                  border-radius: 0.1rem;
                "
                v-if="item.status == 4"
                @click="Confirm(item.id)"
              >
                Confirm receipt
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
    <page
      ref="page"
      :ofPages="ofPages"
      :curPage="curPage"
      @change_page_num="get_page_num"
      @pageActive="pageActive"
    />
  </div>
</template>
<script>
import Page from "./childcomponents/MainPager";
import {
  getOrderslist,
  getOrdersCount,
  cancelOrders,
  confirmReceipt,
} from "network/order";
export default {
  name: "MyOrder",
  components: { Page },

  data() {
    return {
      options: ["All orders", "To be confirmed", "Shipped"],
      optionsIndex: 0,
      ofPages: 0,
      orderslist: [],
      curPage: 1,
      curSize: 24,
    };
  },
  created() {
    /*进入页面  请求所有的表单*/
    this.getOrderslist(0, this.curPage, this.curSize);
    /*进入页面 请求上面的4个选项 各有多少个list*/
    getOrdersCount().then((res) => {
      if (res.data.code == 1) {
        for (let i = 0; i < this.options.length; i++) {
          if (i == 0) {
            this.options[i] += "(" + res.data.data.num_total + ")";
          } else if (i == 1) {
            this.options[i] += "(" + res.data.data.confirm_total + ")";
          } else if (i == 2) {
            this.options[i] += "(" + res.data.data.for_the_goods_total + ")";
          }
        }
      }
    });
  },
  methods: {
    //跳转详情页
    detail(id) {
      this.$router.push({
        path: "/member/orderDetail",
        query: {
          id,
        },
      });
    },

    //申请售后
    path(id, index) {
      this.$router.push({
        path: "/member/ruturnPage",
        query: {
          id,
          index,
        },
      });
    },
    //取消订单
    cancelOrders(id) {
      cancelOrders(id).then((res) => {
        if (res.data.code == 1) {
          this.$notify({
            message: res.data.msg,
            type: "success",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
          this.getOrderslist();
        } else {
          this.$notify({
            message: res.data.msg,
            type: "error",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
        }
      });
    },

    //确认收货
    Confirm(id) {
      confirmReceipt(id).then((res) => {
        if (res.data.code == 1) {
          this.$notify({
            message: res.data.msg,
            type: "success",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
          this.getOrderslist();
        } else {
          this.$notify({
            message: res.data.msg,
            type: "error",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
        }
      });
    },

    /* 根据上面选择的项  请求对应的产品 */
    pick(index) {
      this.curPage = 1;
      this.curSize = 24;
      this.$refs.page.page = 1;
      this.$refs.page.cur = 24;
      if (index == 0) {
        this.getOrderslist(0, this.curPage, this.curSize);
      }
      if (index == 1) {
        this.getOrderslist(1, this.curPage, this.curSize);
      }
      if (index == 2) {
        this.getOrderslist(4, this.curPage, this.curSize);
      }
      if (index == 3) {
        this.getOrderslist(5, this.curPage, this.curSize);
      }
      this.optionsIndex = index;
    },

    /*选择当前一页展示几项  从第一页开始 */
    get_page_num(data) {
      this.curPage = 1;
      this.curSize = data;
      this.$refs.page.page = 1;
      this.getOrderslist(this.optionsIndex, this.curPage, this.curSize);
    },
    /*选择页数*/
    pageActive(data) {
      this.curPage = data;
      this.getOrderslist(this.optionsIndex, this.curPage, this.curSize);
    },
    /*请求产品*/
    getOrderslist(status, num, size) {
      getOrderslist(status, num, size).then((res) => {
        if (res.data.code == 1) {
          this.orderslist = res.data.data.orders;
          this.ofPages = res.data.data.page_total;
        } else {
          this.orderslist = [];
        }
      });
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
}
.pick-item-cur {
  padding: 0.25rem 0;
  color: #ff7f00;
  font-size: 0.5rem;
  border-bottom: 2px solid #ff7f00;
  cursor: pointer;
}
.pick-item {
  padding: 0.25rem 0;
  color: #333333;
  font-size: 0.5rem;
  border-bottom: 2px solid #e0e0e0;
  cursor: pointer;
}
.pick-cur {
  padding: 0 0.25rem;
  border-right: 1px solid #ededed;
}
.pick-item:last-child .pick-cur {
  border-right: none;
}
.pick-item-cur:last-child .pick-cur {
  border-right: none;
}
.list {
  margin-top: 1rem;
}
.item {
  margin-bottom: 1rem;
}
.item-list {
  padding: 0;
  border: 1px solid #e0e0e0;
}
.label {
  font-size: 0.35rem;
  color: #666666;
  background: #f2f2f2;
  padding: 0.25rem 0;
  margin: 0.5rem 0;
}
.label section {
  padding: 0;
  text-align: center;
}
.item-top section {
  padding: 0;
  font-size: 0.35rem;
  color: #999999;
}
.item-top {
  background: #f2f2f2;
  padding: 0.1rem 0.25rem;
}
.item-top section img {
  cursor: pointer;
}
.item-order-left {
  padding: 0;
}

.item-coatProduct section {
  padding: 0;
  font-size: 0.3rem;
  color: #999999;
  text-align: center;
  padding: 0.25rem 0;
  border-top: 1px solid #e0e0e0;
}
.item-order-right {
  padding: 0;
}
.item-order-right section {
  padding: 0;
  font-size: 0.3rem;
  color: #999999;
  text-align: center;
  border: 1px solid #e0e0e0;
}
.item-coatProduct section img {
  width: 80%;
}
.right-button {
  margin-top: 0.25rem;
  cursor: pointer;
}
.remove {
  display: flex;
  justify-content: flex-end;
}
.remove img {
  width: 16px;
  height: 15px;
}
.status-but p {
}
</style>