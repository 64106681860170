<template>
  <div class="post">
    <div class="amount">
      <section>View</section>
      <div class="option-cur">
        <div class="cur-item" @click="showLi">
          <p>{{ cur }}</p>
          <img src="~assets/images/icon/loginHover.png" alt="" />
        </div>
        <ul v-if="curLi">
          <li @click="getItem(item)" v-for="item in options">
            {{ item }}
          </li>
        </ul>
      </div>
      <section>Per Page</section>
    </div>

    <div class="page" v-if="ofPages > 1">
      <div
        class="pageBut"
        @click="pagePrev"
        :class="{ prevStatus: !prevStatus }"
      >
        <p class="pageText"><<</p>
      </div>
      <div class="pageBut d-block d-lg-none d-md-none">
        <p class="pageText">{{ page }}</p>
      </div>

      <div class="d-none d-md-flex d-lg-flex" v-for="item in ofPages">
        <p
          class="pageItem"
          @click="pageItem(item)"
          v-if="pageItemStatus(item)"
          :class="{ activePageItem: item === page }"
        >
          {{ item }}
        </p>
        <p class="pageItemPoint" v-if="pagePointStatus(item)">...</p>
      </div>
      <div
        class="pageBut"
        @click="pageNext"
        :class="{ prevStatus: !nextStatus }"
      >
        <p class="pageText pageText2">>></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainPager",
  data() {
    return {
      page: 1,
      active: 0,
      cur: 24,
      options: [24, 48, 72, 96],
      curLi: false,
    };
  },
  props: {
    ofPages: {
      type: Number,
      default: 1,
    },
    num: {
      type: Number,
      default: 24,
    },
  },
  methods: {
    showLi() {
      this.curLi = !this.curLi;
    },
    getItem(item) {
      this.curLi = false;
      this.cur = item;
      this.$emit("change_page_num", this.cur);
    },

    getActive() {
      this.$emit("pageActive", this.page);
    },

    pagePrev() {
      if (this.prevStatus) {
        this.page -= 1;
        this.getActive();
      }
    },
    pageNext() {
      if (this.nextStatus) {
        this.page += 1;
        this.getActive();
      }
    },
    pageItem(page) {
      this.page = page;
      this.getActive();
    },
    pageItemStatus(item) {
      if (this.ofPages > 6) {
        if (
          item <= 2 ||
          item == this.ofPages ||
          (item > this.page - 2 && item <= this.page) ||
          this.page == item
        ) {
          return true;
        }
      } else {
        return true;
      }
    },
    pagePointStatus(item) {
      if (
        (item >= 2 && item != this.ofPages && item == this.page + 1) ||
        (item == 3 && this.page > 4 && this.ofPages > 3)
      ) {
        return true;
      }
    },
  },
  computed: {
    prevStatus() {
      return this.page > 1;
    },
    nextStatus() {
      return this.page < this.ofPages;
    },
  },

  watch: {
    page(newValue) {
      this.$emit("pagerPage", newValue);
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
}
.post {
  margin: 0.5rem 0;
  display: flex;
  justify-content: space-between;
}
.amount {
  display: flex;
  font-size: 0.4rem;
  color: #4c4c4c;
  align-items: center;
}
.cur-item {
  width: 2rem;
  border: 1px solid #737373;
  border-radius: 0.25rem;
  padding: 0 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin: 0 0.25rem;
}
.cur-item img {
  width: 0.3rem;
  height: 0.25rem;
}
.option-cur {
  position: relative;
}
.option-cur ul {
  background: #fff;
  position: absolute;
  width: 2rem;
  border-radius: 0.25rem;
  overflow-x: hidden;
  z-index: 9;
  border: 1px solid #ededed;
  box-shadow: 0.1rem 0.1rem 0.1rem #ededed;
  font-size: 0.4rem;
  margin-left: 0.25rem;
}
.option-cur ul li {
  color: #333333;
  padding: 0 0.25rem;
}
.option-cur ul li:hover {
  color: #ff7f00;
  background: #ffecd9;
}

.page {
  display: flex;
  height: 1rem;
}

.pageBut {
  display: flex;
  align-items: center;
  border: 1px solid #ff7f00;
  color: #ff7f00;
  cursor: pointer;
}

.pageBut:active {
  background-color: #ff7f00;
  color: #fff;
}

.icon2 {
  display: none;
}

.pageBut:active .icon1 {
  display: none;
}

.pageBut:active .icon2 {
  display: block;
}

.pageItem {
  display: flex;
  align-items: center;
  padding: 0.2rem 0.35rem;

  margin: 0 0.1rem;
  border: 1px solid #ff7f00;
  color: #ff7f00;
  font-size: 0.4rem;
  cursor: pointer;
}

.activePageItem {
  background-color: #ff7f00;
  color: #fff;
}

.prevStatus,
.prevStatus:active {
  border-color: #d9d9d9;
  background: none;
  color: #d9d9d9;
}

.pageBut {
  padding: 0.1rem;
  margin: 0 0.1rem;
}

.pageText {
  font-size: 0.4rem;
  margin: 0;
}

.pageItemPoint {
  font-size: 0.6rem;
  color: #ff7f00;
}
@media screen and (max-width: 992px) {
  /*bootstrap 中屏幕*/
}

@media screen and (max-width: 768px) {
  /*bootstrap 小屏幕*/
}

@media screen and (min-width: 1179px) {
}
</style>