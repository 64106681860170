import { request } from "./request";


//请求订单列表
export function getOrderslist(status, pageNumber, pageSize) {
    return request({
        url: '/api/orders/getOrderslist',
        params: {
            status: status,
            pageNumber: pageNumber,
            pageSize: pageSize,
        },
    })
};


//请求订单列表
export function cartAddToOrders(ids, company, po_no, terms, rep, ship_date, ship_via, cancel_date, note) {
    return request({
        url: '/api/orders/cartAddToOrders',
        method: 'post',
        data: {
            ids: ids,
            company: company,
            po_no: po_no,
            terms: terms,
            rep: rep,
            ship_date: ship_date,
            ship_via: ship_via,
            cancel_date: cancel_date,
            note: note,
        },

    })
};




//请求订单列表

export function getOrderslistByids(ids) {
    return request({
        url: '/api/orders/getOrderslistByids',
        params: {
            ids: ids,
        },

    })
};


//订单选项长度
export function getOrdersCount() {
    return request({
        url: '/api/orders/getOrdersCount',
    })
};


/*订单详情*/
export function getOrdersByid(id) {
    return request({
        url: '/api/orders/getOrdersByid',
        params: {
            id: id,
        },

    })
};

/*订单单条详情*/
export function getOrdersitemByid(id) {
    return request({
        url: '/api/orders/getOrdersitemByid',
        params: {
            id: id,
        },

    })
};


/*退换货列表*/
export function getServicelist(pageNumber, pageSize) {
    return request({
        url: '/api/service/getServicelist',
        params: {
            pageNumber: pageNumber,
            pageSize: pageSize,
        },

    })
};



//取消订单
export function cancelOrders(id) {
    return request({
        url: '/api/orders/cancelOrders',
        method: 'post',
        data: {
            id: id,
        },

    })
};


//确认收货
export function confirmReceipt(id) {
    return request({
        url: '/api/orders/confirmReceipt',
        method: 'post',
        data: {
            id: id,
        },

    })
};

//整个订单退换货
export function addServiceByOrders(type, id, reason, description, picture) {
    return request({
        url: '/api/service/addServiceByOrders',
        method: 'post',
        data: {
            type: type,
            id: id,
            reason: reason,
            description: description,
            picture: picture,
        },
    })
};

//单个订单退换货
export function addServiceByOrdersItem(type, id, reason, description, picture) {
    return request({
        url: '/api/service/addServiceByOrdersItem',
        method: 'post',
        data: {
            type: type,
            id: id,
            reason: reason,
            description: description,
            picture: picture,
        },
    })
};


//退换货详情
export function getServiceByid(id) {
    return request({
        url: '/api/service/getServiceByid',
        params: {
            id: id,
        },
    })
};


//重新退换货
export function reapplyService(id) {
    return request({
        url: '/api/service/reapplyService',
        method: 'post',
        data: {
            id: id,
        },
    })
};


//确认收到换货
export function confirmReceiptReplacement(id) {
    return request({
        url: '/api/service/confirmReceiptReplacement',
        method: 'post',
        data: {
            id: id,
        },
    })
};



//提交成功订单详情请求
export function getOrdersPrint(id) {
    return request({
        url: '/api/orders/getOrdersPrint',
        params: {
            id: id,
        },
    })
};